<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">高新分院简介</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:center;">
          <span style="font-size:16px;">高新分院——“向高而攀，向新而行”</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">高新分院始终秉持提供高质量、高水平规划设计和咨询服务的宗旨，注重引入新技术、新理念和新方法，不断创新规划设计理念，致力于长期为政府、社会、市民提供新时代城市高质量发展的高水平规划解决方案。
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">高新分院依托总公司在规划领域具备专业水平和丰富经验的总体规划所、国土研究所和工程咨询所等组成。技术人员专业涵盖城乡规划、土地资源管理、地理信息、工程咨询等领域，其中国家注册城乡规划师11名，国家注册工程咨询师2名，副高级以上工程师10名，能够为各类规划项目提供全方位的专业支持。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>院长：高程</b></span> 
        </p>
        <div style="display: inline-block;text-align:center;width: 49%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/“总体规划所—旧 ”大.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">总体规划所</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 49%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/“国土研究所1”大.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">国土研究所</div> 
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>